import { ref, watch } from "vue";
import { defineStore } from "pinia";

import { API } from "@/services/api";

import useUserStore from "./user";

const useQuotaStore = defineStore("quota", () => {
    const quotaInfo = ref(null);

    async function load() {
        const quotaRes = await API.billing.getQuotaInfo();
        quotaRes.exhausted = quotaRes.limit !== null && quotaRes.used >= quotaRes.limit;
        quotaInfo.value = quotaRes;
    }

    async function decr(cost) {
        if (!cost) return;
        if (quotaInfo.value === null) return;
        if (quotaInfo.value.limit === null) return;
        quotaInfo.value.used += cost;
    }

    async function setExhausted() {
        if (quotaInfo.value === null) return;
        quotaInfo.value.exhausted = true;
    }

    async function reset() {
        quotaInfo.value = null;
    }

    const userStore = useUserStore();
    watch(
        () => userStore.isLoggedIn,
        async (val) => {
            if (val) await load();
            else reset();
        },
        { immediate: true },
    );

    return {
        quotaInfo,

        refresh: load,
        decr,
        setExhausted,
    };
});

export default useQuotaStore;
