<script setup lang="ts">
import { ref } from "vue";

import DropdownMenu from "./DropdownMenu.vue";
import Popup from "./Popup.vue";

defineProps({
    modelValue: [String, Number],
    options: {
        type: Object,
        default() {
            return [];
        },
    },
    displayed: {
        type: String,
        default: null,
    },
    displayedIcon: {
        type: String,
        default: null,
    },
    displayedEmoji: {
        type: String,
        default: null,
    },
    direction: {
        type: String,
        default: "bottom",
    },
    disabled: Boolean,
    cramped: Boolean,
    alignText: {
        type: String,
        default: "center",
    },
    menuType: {
        type: String,
        default: "dropdown",
    },
    menuName: String,
});

const emit = defineEmits(["update:modelValue", "update"]);

const isOptionsOpen = ref(false);

function changeSelection(option) {
    emit("update:modelValue", option);
    emit("update");
    isOptionsOpen.value = false;
}
</script>

<template>
    <div
        class="select-container"
        :class="[{ disabled, cramped }, `align-${alignText}`]"
        @blur="() => {if (menuType !== 'popup') isOptionsOpen = false}"
        tabindex="-1"
    >
        <div @click="isOptionsOpen = true" class="select">
            <div class="value-container">
                <div class="cur-value">
                    <img v-if="displayedIcon" :src="displayedIcon.toString()" />
                    <span v-if="displayedEmoji" class="emoji">{{ displayedEmoji }}</span>
                    <span class="displayed-option">{{ displayed ?? modelValue }}</span>
                </div>
                <template v-if="menuType === 'dropdown'">
                    <i v-if="isOptionsOpen && !disabled" class="material-icons">expand_less</i>
                    <i v-else class="material-icons">expand_more</i>
                </template>
            </div>
        </div>
        <template v-if="!disabled && isOptionsOpen">
            <DropdownMenu
                v-if="menuType === 'dropdown'"
                :options="options"
                cramped
                :direction="direction"
                @change="(value) => changeSelection(value)"
            ></DropdownMenu>
            <Popup
                v-else-if="menuType === 'popup'"
                :show="isOptionsOpen"
                @close="isOptionsOpen = false"
            >
                <template v-slot:header>
                    {{ menuName }}
                </template>
                <div class="popup-options">
                    <div
                        class="popup-option"
                        v-for="[key, value] in Object.entries(options)"
                        :key="key"
                        @click="changeSelection(key)"
                    >
                        <img v-if="value.icon" :src="value.icon">
                        <span v-if="value.emoji" class="emoji">{{ value.emoji }}</span>
                        <span>{{ value.label }}</span>
                    </div>
                </div>
            </Popup>
        </template>
    </div>
</template>

<style scoped>
.select-container {
    position: relative;
    border: none;
    border-radius: 5px;
    align-items: center;
    display: flex;
    cursor: pointer;
    border: 1px solid #9E97BC;
    transition: all .1s linear;
    user-select: none;
}

.select-container:hover {
    border: 1px solid #2d00d4;
}

.select-container.disabled {
    color: #00000088;
}

.select {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 6px;
}

.select.cramped {
    padding: 3px;
}

.value-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}

.value-container:not(:first-child) {
    margin-left: 6px;
}

.value-container > i {
    margin-left: 5px;
}

.select-container.cramped > .value-container:not(:first-child) {
    margin-left: 3px;
}

.cur-value {
    display: flex;
    flex: 1;
    gap: 8px;
}

.cur-value img {
    height: 16px;
    width: 16px;
    display: inline-flex;
}

.cur-value span {
    width: 100%;
    text-align: center;
}

.select-container.align-left .cur-value span {
    text-align: start !important;
}

.select-container.align-right .cur-value span {
    text-align: end !important;
}

.options {
    right: 0;
}

/* Popup menu */

.popup-options {
    display: flex;
    gap: 12px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 500px;
}

@media(max-width: 600px) {
    .popup-options {
        width: 100%;
        flex-direction: column;
    }
}

.popup-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 30%;
    gap: 8px;
    border: 1px solid #DAD5EF;
    padding: 8px 12px;
    border-radius: 8px;
    flex: 1;
    transition: .1s all linear;
    cursor: pointer;
}

.popup-option span {
    text-wrap: nowrap;
}

.popup-option:hover {
    border: 1px solid #2d00d4;
}

.popup-option img {
    height: 20px;
    width: 20px;
}

.displayed-option {
    text-wrap: nowrap;
}

.emoji {
    font-size: 20px;
    font-family: "Twemoji";
}
</style>
