<script setup>
import Button from "@/components/common/Button.vue";

import refreshIcon from "@/assets/img/icons/refresh.svg";

const emit = defineEmits(["retry"]);

const props = defineProps({
    message: {
        type: String,
        required: true,
    },
    onRetry: {
        type: Function,
    }
});
</script>

<template>
    <div class="error-container">
        <i class="material-icons error-icon">error</i>
        <span class="error">
            {{ message }}
        </span>
        <Button
            v-if="props.onRetry"
            :imgSrc="refreshIcon"
            text="Retry"
            @click="emit('retry')"
        ></Button>
    </div>
</template>

<style scoped>
.error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #00000080;
    padding: 20px;
    user-select: none;
    height: 100%;
}

.error-container > * {
    vertical-align: middle;
}

.error-container > *:not(:last-child) {
    margin-bottom: 10px;
}

.error {
    text-align: center;
}

.error-icon {
    font-size: 2.5em;
}
</style>
