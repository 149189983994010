import useQuotaStore from "@/stores/quota";

import MD5 from "crypto-js/md5";
import cache from "./cache";

let quotaStore;

const pendingApiRequests = new Map();

/**
 * Sends an API request and wraps the result or error
 * @param apiFn API route function
 * @param params API route params
 * @param checkDomain If true, checks the domain of `params.url` against the whitelist
 * @returns Wrapped API route response
 */
async function sendApiRequest(apiFn, params, force = false) {
    if (!quotaStore) quotaStore = useQuotaStore();
    // Check current session storage for a cached result with the same hash made from all params
    const hash = MD5(JSON.stringify({ ...params, function: apiFn.name })).toString();
    const cached = await cache.get(hash);
    if (cached && !force) return JSON.parse(cached);

    // Check the pending requests map if there is promise with the same params hash
    if (pendingApiRequests.has(hash)) {
        return pendingApiRequests.get(hash);
    }

    // Make the request and add it to the dedup map while it's ongoing
    let res;
    try {
        const promise = apiFn(params);
        pendingApiRequests.set(hash, promise);
        res = await promise;
    } catch (err) {
        if (err.error !== "article_not_found") console.error(err);
        if (err.error === "quota_exhausted") quotaStore.setExhausted();
        throw err;
    } finally {
        pendingApiRequests.delete(hash);
    }

    // Save result to cache
    if (res instanceof WebSocket) {
        let error = false;
        const messages = [];
        res.addEventListener("message", (message) => {
            const data = JSON.parse(message.data);
            if (typeof data === "object" && data.error) {
                if (data.error === "quota_exhausted") quotaStore.setExhausted();
                error = true;
                return;
            }
            messages.push(data);
        });

        res.addEventListener("close", (event) => {
            if (error) return;
            quotaStore.decr(apiFn.quotaCost);
            if (event.reason === "extension_interrupt" || !messages.length) return;
            cache.set(hash, messages);
        });
    } else {
        quotaStore.decr(apiFn.quotaCost);
        cache.set(hash, res);
    }

    return res;
}

export default sendApiRequest;
