<script setup>
defineProps({
    checked: Boolean,
    disabled: Boolean,
});

const emit = defineEmits(["switch", "update:checked"]);

function emitSwitch(e) {
    const { target } = e;
    emit("update:checked", target.checked);
    emit("switch");
}
</script>

<template>
    <div class="switch-container">
        <slot name="optionOff"></slot>
        <label class="switch">
            <input
                type="checkbox"
                :checked="checked"
                @change="emitSwitch"
                :disabled="disabled"
            >
            <span class="slider round"></span>
        </label>
        <slot name="optionOn"></slot>
    </div>
</template>

<style scoped>
.switch-container {
    display: flex;
    align-items: center;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 16px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ECEFEE;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: -4px;
    bottom: -2px;
    background-color: #A2A2A2;
    -webkit-transition: .4s;
    transition: .4s;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

input:checked + .slider {
    background-color: #8FA3EB;
}

input:checked + .slider:before {
    background-color: #2D00D4;
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
