function get(hash) {
    const key = `cache:${hash}`;
    const cached = window.sessionStorage.getItem(key);
    return cached;
}

function set(hash, value) {
    const key = `cache:${hash}`;
    let lastError;
    const data = JSON.stringify(value);
    for (let attempt = 1; attempt <= 3; attempt++) {
        try {
            window.sessionStorage.setItem(key, data);
            return;
        } catch (err) {
            // Session storage might be full, remove a random entry to make some room
            lastError = err;
            const keys = Object.keys(window.sessionStorage).filter((k) => k.startsWith("cache:"));
            const i = Math.floor(Math.random() * keys.length);
            window.sessionStorage.removeItem(keys[i]);
        }
    }
    console.error("Failed to save result to cache:", lastError);
}

export default {
    get,
    set,
};
