<script setup>
import {
    onMounted,
    ref,
} from "vue";

const props = defineProps({
    cap: {
        type: Number,
        required: true,
    }
});

const longLoading = ref(false);

onMounted(() => {
    setTimeout(() => longLoading.value = true, props.cap * 1000);
});
</script>

<template>
    <div class="loading-block">
        <slot></slot>
        <div class="long-loading" v-if="longLoading">
            <span>We are preparing your response.<br/>Hang tight for just a moment.</span>
        </div>
    </div>
</template>

<style scoped>
.loading-block {
    position: relative;
}

.long-loading {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    top: 0;
    height: 100%;
    backdrop-filter: blur(2px);
}

.long-loading > span {
    font-size: 15px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 15px;
    line-height: 20px;
}
</style>
