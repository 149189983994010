<script setup>
</script>

<template>
    <div class="locked-icon">
        <img src="@/assets/img/icons/lock.svg" />
        Pro
    </div>
</template>

<style scoped>
.locked-icon {
    padding: 2px 4px 2px 2px;
    background-color: #2D00D4;
    color: white;
    border-radius: 4px;
    margin-right: 12px;
    display: flex;
    align-items: center;
}

.locked-icon > img {
    filter: invert(100%) sepia(0%) saturate(7487%) hue-rotate(63deg) brightness(106%) contrast(100%);
    margin-right: 2px;
    height: 16px;
    width: 16px;
}
</style>
