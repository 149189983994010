<script setup>
import { marked } from "marked";

defineProps(["markdown", "compact"]);

// Markdown

function adaptMarkdown(data) {
    const renderer = {
        link(href, title, text) {
            return `
                <a
                    class=inline-source
                    id=inline-source
                    href=${href}
                    target="_blank"
                >${text}</a>
            `;
        },
        heading(text, level) {
            return `<h${level}>${text}</h${level}>`;
        },
        strong(text) {
            return `<strong>${text}</strong>`;
        },
    };
    marked.use({ renderer });
    const md = marked.parse(data);

    return md;
}
</script>

<template>
    <div
        v-if="markdown"
        class="markdown-result"
        :class="{ compact }"
        ref="markdownContainer"
        v-html="adaptMarkdown(markdown)"
    ></div>
</template>

<style scoped>
</style>

<style>
.markdown-result {
    word-break: break-word;
}

.markdown-result p, .markdown-result li {
    margin: 6px 0 18px 0;
}

.markdown-result p:last-child, .markdown-result li:last-child {
    margin-bottom: 8px;
}

.markdown-result .inline-source {
    display: inline-block;
    cursor: pointer;
    user-select: none;
    font-size: 11px;
    font-weight: 700;
    vertical-align: top;
    color: #F6F5FD;
    text-decoration: none;
    background-color: #9d96ba;
    border-radius: 50%;
    line-height: 14px;
    height: 14px;
    width: 14px;
    text-align: center;
}

.markdown-result.hide-inline-sources .inline-source, .compact .inline-source {
    display: none;
}

.markdown-result ol, .markdown-result ul {
    margin: 0;
    padding-left: 20px;
}

.markdown-result ol li::marker, .markdown-result ul li::marker {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);
}

.markdown-result li p, .markdown-result ul p {
    margin-left: 2px;
}

.markdown-result h1 {
    font-size: 15.5px;
    font-weight: 700;
    color: #2D00D4;
    margin-bottom: 8px;
}

.markdown-result h2 {
    font-size: 15px;
    font-weight: 600;
    color: #2D00D4;
    margin-bottom: 8px;
}

.markdown-result h3 {
    font-size: 14px;
    font-weight: 600;
    color: #3F4842;
    margin-bottom: 8px;
}

.compact h1, .compact p {
    font-size: unset;
    color: unset;
    margin-bottom: unset;
}

.markdown-result strong {
    font-weight: 700;
}

pre, code {
    white-space: break-spaces;
}
</style>
